
import PrivacyPolicyText from "../components/PrivacyPolicyText.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export const PrivacyPolicyComponent = defineComponent({
  components: { PrivacyPolicyText },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

export default PrivacyPolicyComponent;
