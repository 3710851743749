
import { defineComponent, computed } from "vue";
import { useStore } from "../store";

export const PrivacyPolicyTextComponent = defineComponent({
  setup() {
    const store = useStore();
    const appType = computed(() =>
      [""].includes(store.state.runtimeConfig.type)
        ? store.state.runtimeConfig.type
        : "GENERAL"
    );
    return {
      appType,
    };
  },
});

export default PrivacyPolicyTextComponent;
