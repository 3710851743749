import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92a58be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "privacy-policy" }
const _hoisted_2 = { class: "privacy-policy__box" }
const _hoisted_3 = { class: "privacy-policy__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_PrivacyPolicyText = _resolveComponent("PrivacyPolicyText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.t("PRIVACY_POLICY.HEADER")), 1 /* TEXT */),
      _createVNode(_component_a_divider),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PrivacyPolicyText)
      ])
    ])
  ]))
}